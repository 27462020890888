<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate('')"
        >新增</el-button
      >
      <el-input
        v-model="listQuery.name"
        placeholder="请输入试卷名称"
        style="width: 150px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.type"
        placeholder="请选择试卷分类"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="随堂练习" :value="1" />
        <el-option label="课后作业" :value="2" />
        <el-option label="班级考试" :value="3" />
      </el-select>
      <el-date-picker
        v-model="listQuery.school_year"
        type="year"
        class="filter-item"
        value-format="yyyy"
        placeholder="选择学年"
        clearable
      >
      </el-date-picker>
      <el-button class="filter-item" type="primary" @click="handleFilter"
        >搜索</el-button
      >
    </div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="试卷名" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="试卷分类" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.type == 1
              ? "随堂练习"
              : scope.row.type == 2
              ? "课后作业"
              : "班级考试"
          }}
        </template>
      </el-table-column>
      <el-table-column label="学年" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.school_year }}
        </template>
      </el-table-column>
      <el-table-column label="学期" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.semester == 1 ? "第一学期" : "第二学期" }}
        </template>
      </el-table-column>
      <el-table-column label="题目数量" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.question_num }}</template>
      </el-table-column>
      <el-table-column label="考试时长" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.duration }} </template>
      </el-table-column>
      <el-table-column label="总分" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.total_score }} </template>
      </el-table-column>
      <el-table-column label="答题学生数量" min-width="110" align="center">
        <template slot-scope="scope"
          >{{ scope.row.student_answer_num }}
        </template>
      </el-table-column>
      <el-table-column label="排序" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.sort_weight }} </template>
      </el-table-column>
      <el-table-column label="上架状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_on == 1" type="success">上架</el-tag>
          <el-tag v-else type="danger">下架</el-tag>
        </template>
      </el-table-column>

      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.is_on == 1"
            size="mini"
            type="danger"
            @click="handleStatus(scope.row.id, '下架')"
            >下架</el-button
          >
          <el-button
            v-else
            size="mini"
            type="success"
            @click="handleStatus(scope.row.id, '上架')"
            >上架</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="handleCreate(scope.row.id)"
          >
            修改
          </el-button>
          <el-button
            size="mini"
            style="margin-top: 10px"
            type="warning"
            @click="initPassword(scope.row.id)"
            >题目管理</el-button
          >
          <el-button
            size="mini"
            style="margin-top: 10px"
            type="primary"
            @click="handleRemind(scope.row)"
            >学生答题情况</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      list: [],
      total: null,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        name: "",
        type: "",
        school_year: "",
      },
      form: {
        id: "",
        name: "",
        province_code: "",
        city_code: "",
        contact_info: [],
        account: "",
        expire_at: "",
      },
      dialogFormVisible: false,
      dialogStatus: "",
      textMap: {
        update: "修改",
        create: "新增",
      },
      rules: {
        name: [
          { required: true, message: "学校名称不能为空", trigger: "change" },
        ],
        account: [
          { required: true, message: "账号不能为空", trigger: "change" },
        ],
        city_code: [
          { required: true, message: "机构地址不能为空", trigger: "change" },
        ],
        contact_info: [
          {
            type: "array",
            required: true,
            message: "联系信息不能为空",
            trigger: "change",
          },
        ],
        expire_at: [
          { required: true, message: "到期时间不能为空", trigger: "change" },
        ],
      },
      btnLoading: false,
      provinces: [],
      cities: [],
    };
  },
  created() {
    this.getList();
    // this.getProvince();
  },
  methods: {
    getProvince() {
      return request({
        url: "/api/common/china/provinces",
        method: "get",
      }).then((res) => {
        this.provinces = res.data;
      });
    },
    handelAttrDown(index) {
      if (index >= this.form.contact_info.length - 1) {
        return;
      }
      this.form.contact_info.splice(
        index + 1,
        0,
        this.form.contact_info.splice(index, 1)[0]
      );
    },
    handelAttrUp(index) {
      if (index == 0) {
        return;
      }
      this.form.contact_info.splice(
        index - 1,
        0,
        this.form.contact_info.splice(index, 1)[0]
      );
    },
    handelAttrDel(index) {
      this.form.contact_info.splice(index, 1);
    },
    add() {
      this.form.contact_info.push({ contacts: "", contact_phone: "" });
    },
    getReCities(e) {
      request({
        url: "/api/common/china/cities",
        method: "get",
        params: {
          province_code: e,
        },
      }).then((res) => {
        this.cities = res.data;
      });
    },
    closeReCities() {
      this.cities = [];
      this.form.city_code = "";
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/teacherend/teacherPaper/list",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    resetForm() {
      this.form = {
        id: "",
        name: "",
        province_code: "",
        city_code: "",
        contact_info: [],
        account: "",
        expire_at: "",
      };
    },
    handleCreate(id) {
      this.$router.push(`/question/testpaper_edit?id=${id}`);
    },
    handleUpdate(row) {
      this.resetForm();
      this.form = Object.assign({}, row);
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    saveData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          // form.contact_info
          for (let index = 0; index < this.form.contact_info.length; index++) {
            const item = this.form.contact_info[index];
            console.log(item);
            if (!item.contacts) {
              this.$message({
                type: "warning",
                message: "联系人不能为空",
              });
              return;
            } else if (!item.contact_phone) {
              this.$message({
                type: "warning",
                message: "联系人电话不能为空",
              });
              return;
            }
          }
          this.btnLoading = true;
          request({
            url: "/api/teacherend/school/store",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    handleStatus(val, text) {
      this.$confirm("此操作将" + text + "该院系, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/teacherend/teacherPaper/upDown",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    initPassword(id) {
      this.$router.push(`/question/topic?id=${id}`);
    },
    handleRemind(row) {
      this.$router.push(`/question/student_condition?id=${row.id}`);
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>